import PropTypes from "prop-types";
import { Fragment } from "react";
import classNames from "classnames";
import RelativeLink from "app/pages/.shared/RelativeLink";
import "./breadcrumb.scss";
import IconBreadcrumCheck from "app/pages/.shared/static/icons/IconBreadcrumCheck";

const BreadcrumbItem = ({
	to,
	label,
	isActive,
	isDisabled,
	position,
	isPassed,
	onClick,
	disableClick,
}) => {
	const breadCrumbStyle = { pointerEvents: disableClick ? "none" : "initial" };

	const breadcrumbClass = classNames({
		"breadcrumbs-item": true,
		"breadcrumbs-item--active": isActive,
		"breadcrumbs-item--disabled": isDisabled,
		"breadcrumbs-item--passed": isPassed,
	});

	return (
		<Fragment>
			<li className={breadcrumbClass} style={breadCrumbStyle}>
				<RelativeLink to={{ pathname: to }} onClick={onClick}>
					<div className="breadcrumbs-item__position">
						{isPassed ? <IconBreadcrumCheck height={8} width={16} /> : position}
					</div>
					<span className="breadcrumbs-item__label breadcrumbs-item__label">{label}</span>
				</RelativeLink>
			</li>
			<div className="breadcrumbs-item__spacer" />
		</Fragment>
	);
};

BreadcrumbItem.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	to: PropTypes.string,
	isActive: PropTypes.bool,
	isPassed: PropTypes.bool,
	isDisabled: PropTypes.bool,
	position: PropTypes.number,
	onClick: PropTypes.func,
	disableClick: PropTypes.bool,
};

BreadcrumbItem.defaultProps = {
	onClick: () => {},
};

export default BreadcrumbItem;
