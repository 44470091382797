import { connect } from "react-redux";
import SmartDP from "app/pages/SmartDP/SmartDP";
import { getPartnerMarketingHomeBlocks } from "app/reducers/partnerSelector";

const mapStateToProps = state => {
	const currentMerchCode = state.merchandising?.current?.code;
	const booking = currentMerchCode ? state.booking.smartDPMerch : state.booking;

	return {
		booking,
		homeBlocks: getPartnerMarketingHomeBlocks(state),
		fetchSDPProductsStatus: state.smartDP.status,
		hasSdpSpecialOffer: state.smartDP.specialOffer, // true si le back renvoi au moins un vol remboursable
		specialOfferInfos: state.smartDP.specialOfferInfos,
	};
};

export default connect(mapStateToProps)(SmartDP);
