import { memo } from "react";
import PropTypes from "prop-types";
import "./MarketingBanner.scss";
import { useRouteMatch, useLocation } from "react-router-dom";
import Paragraphs from "app/pages/.shared/Paragraphs";

const MarketingBanner = ({ banners = [] }) => {
	const { message, backgroundColor, textColor, pages = [], promoCode } = banners[0] || {};

	const match = useRouteMatch();
	const location = useLocation();

	const isValidPage = pages.find(page => {
		return location.pathname === page || (page === "/:productUri" && match.path === page);
	});

	const background = backgroundColor?.hex;
	const text = textColor?.hex;

	return isValidPage && message ? (
		<div
			className="marketing-banner"
			data-testid="marketing-banner"
			style={{ background: background, color: text }}
		>
			{promoCode && (
				<span
					className="marketing-banner__promocode"
					data-testid="marketing-banner-promocode"
				>
					{promoCode}
				</span>
			)}
			<Paragraphs paragraphs={message} enableHTMLFormatting />
		</div>
	) : null;
};

MarketingBanner.propTypes = {
	banners: PropTypes.array,
};

export default memo(MarketingBanner);
