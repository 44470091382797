import { cloneElement, PureComponent } from "react";
import "./breadcrumb.scss";
import PropTypes from "prop-types";

class Breadcrumb extends PureComponent {
	render() {
		const { children = [] } = this.props;
		const items = children.map((item, i) =>
			cloneElement(item, { key: i, position: i + 1 }, null)
		);

		return <ul className="breadcrumbs">{items}</ul>;
	}
}

Breadcrumb.propTypes = {
	children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Breadcrumb;
