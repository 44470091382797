import { memo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import loadable from "@loadable/component";
import SmartDPSearchSummary from "app/pages/SmartDP/SmartDPSearchSummary/SmartDPSearchSummary";
import { AsyncBooking } from "app/routes";
import SmartDPBreadcrumb from "app/pages/SmartDP/Booking/SmartDPBreadcrumb/SmartDPBreadcrumb";
import SmartDPHotelDescriptionContainer from "app/pages/SmartDP/Quote/HotelDescription/SmartDPHotelDescriptionContainer";
import SmartDPHeaderContainer from "app/pages/SmartDP/SmartDPHeader/SmartDPHeaderContainer";
import "./SmartDP.scss";
import { QUOTATION_CODE_STATUS } from "app/constants";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";
import SpecialOfferBanner from "app/pages/.shared/SpecialOffer/SpecialOfferBanner/SpecialOfferBanner";
import { PushNotificationContainer } from "app/pages/.shared/PushNotification/PushNotificationContainer";

export const AsyncSmartDPSearch = loadable(() =>
	import(/* webpackChunkName: "smartdp-search" */ "app/pages/SmartDP/Search/SmartDPSearch")
);

export const AsyncSmartDPListingContainer = loadable(() =>
	import(/* webpackChunkName: "smartdp-listing" */ "app/pages/SmartDP/Listing/SmartDPListingContainer")
);

const SmartDP = ({
	booking = {},
	homeBlocks = [],
	fetchSDPProductsStatus,
	hasSdpSpecialOffer,
	specialOfferInfos = {},
}) => {
	const match = useRouteMatch();

	const searchPage = <AsyncSmartDPSearch homeBlocks={homeBlocks} />;

	const isSDPQuotePage = useRouteMatch({
		path: "/sdp/booking/quote",
		sensitive: true,
	});

	const isSDPListingPage = useRouteMatch({
		path: "/sdp/listing",
		sensitive: true,
	});

	const isSDPBookingPage = useRouteMatch({
		path: "/sdp/booking",
		sensitive: true,
	});

	const headerClass = classNames("smartdp", {
		"smartdp--sticky": isSDPListingPage,
	});

	return (
		<div className={headerClass}>
			<SmartDPHeaderContainer />
			<div className="smartdp__subheader">
				{(isSDPQuotePage || isSDPListingPage) && (
					<>
						{fetchSDPProductsStatus === QUOTATION_CODE_STATUS.SUCCESS ||
						fetchSDPProductsStatus === QUOTATION_CODE_STATUS.FULL_FLIGHT ||
						fetchSDPProductsStatus === QUOTATION_CODE_STATUS.FULL_PROPERTY ? (
							<div className="smartdp__summary">
								<SmartDPSearchSummary
									occupancies={booking.occupancies}
									departureCityLabel={booking.departureCity.label}
									departureResortLabel={booking.destinationResort.label}
								/>
							</div>
						) : (
							<div className="smartdp__summary-loader">
								<LoaderBar width={100} height={15} />
								<LoaderBar width={"90%"} height={15} />
								<LoaderBar width={150} height={15} />
							</div>
						)}
					</>
				)}
				{(isSDPQuotePage || isSDPListingPage) && hasSdpSpecialOffer && (
					<SpecialOfferBanner
						background={specialOfferInfos.ui?.background}
						title={specialOfferInfos.title}
						pictoUrl={specialOfferInfos.ui?.pictoUrl}
					/>
				)}
			</div>
			{isSDPBookingPage && (
				<div className="smartdp__breadcrumb">
					<SmartDPBreadcrumb />
				</div>
			)}
			<Switch>
				<Route path={`${match.url}/search`}>{searchPage}</Route>
				<Route path={[`${match.url}/hotelDescription/:accommodationId/:code`]}>
					<SmartDPHotelDescriptionContainer printMode={true} />
				</Route>
				<Route path={`${match.url}/listing`}>
					<AsyncSmartDPListingContainer />
				</Route>
				<Route
					path={`${match.url}/booking`}
					render={props => {
						return <AsyncBooking {...props} />;
					}}
				/>
				<Route path={`${match.url}/`}>{searchPage}</Route>
			</Switch>
			<PushNotificationContainer />
		</div>
	);
};

SmartDP.propTypes = {
	booking: PropTypes.object,
	homeBlocks: PropTypes.array,
	fetchSDPProductsStatus: PropTypes.string,
	hasSdpSpecialOffer: PropTypes.bool,
	specialOfferInfos: PropTypes.object,
};

export default memo(SmartDP);
